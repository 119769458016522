.program-card{
    display: flex;
    flex-direction: column;
    width: 358px;
    box-shadow: 0 0 20px 4px rgb(0 0 0 / 10%);
    border-radius: 10px;
    overflow: hidden;
    margin: 15px;
}

.prog-details{
    display: flex;
    flex-direction: column;
    padding: 1rem;
}
.img-prog{
    /* width: 358px; */
    height: 150px;
    /* overflow: hidden; */
}
.img-prog>img{
    height: 258px;
}
.prog-tag{
    border-radius: 4px;
    color: #cd0000;
    font-size: 14px;
    font-weight: 500;
    background-color: #ffc107;
    width: fit-content;
    padding: 5px;
    margin-bottom: 0.5rem!important;
    margin-top: 0.5rem!important;
}

.prog-title{
    font-size: 22px;
    line-height: 29px;
    font-weight: 400;
    letter-spacing: 1px;
    margin-bottom: 15px;
    margin-top: 15px;
}

.prog-duration, .prog-deadline{
    font-size: 17px;
    line-height: 21px;
    font-weight: 400;
    letter-spacing: 1px;
    margin: 8px 0;
}

.btn-explore{
    margin: 1rem 0;
    width: fit-content;
    background: none;
    font-size: 1.3rem;
    border: none;
    color: #0091ff;
    font-weight: 700;
    display:flex;
}
@media screen and (max-width: 767px) {
    .program-card{
        display: flex;
        flex-direction: column;
        width: 358px;
        box-shadow: 0 0 20px 4px rgb(0 0 0 / 10%);
        border-radius: 10px;
        overflow: hidden;
        margin: 4px;
    }
  }
  @media screen and (max-width: 1100px) {
    .program-card{
        display: flex;
        flex-direction: column;
        width: 358px;
        box-shadow: 0 0 20px 4px rgb(0 0 0 / 10%);
        border-radius: 10px;
        overflow: hidden;
        margin: 4px;
    }
  }