.AlumniNetwork{
    margin: 16px 10%;
}

h3{
    font-size: 25px;
    line-height: 29px;
    font-weight: 400;
    letter-spacing: 1px;
    font-weight: 700;
}

.an{
    display: flex;
    justify-content: space-between;
    padding-bottom: 2rem;
}

.an-top-right{
    display: flex;
    padding-right: 5rem;
    align-items: center;
}

.btn-cth{
    height: 42px;
    padding: 11px;
    margin-left: 28px;
    color: #ffffff;
    background-image: linear-gradient(to left, #00c4ff 0%, #0091ff 71%);
    border-color: #007bff;
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    border-width: 0px;
    border-radius: 5px;
}

.an-card-header>img{
    width: 75%;
   
}

.an-card-header{
 
    clip-path: polygon(0 0, 100% 0, 100% 60%, 0 88%);
    height: 249px;
}

.an-card{
    width: 16rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 36rem;
    background-color: #fff;
    width: 19rem !important;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 8px;
    margin-bottom: 2rem;
    overflow: hidden;
}

.alumni-cards{
    display: flex;
}

.an-review{
    margin: 0 0 15px;
    overflow-wrap: break-word;
    padding: 1rem;
    padding-top: 0;
    font-size: 1rem;
    height: 110px;
}

.an-details{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    padding-top: 0;
    height: 55px;
}
.an-name{
    font-size:1rem;
}
.an-details>*{
    padding-bottom: 0.3rem;
   
}

.an-designation{
    font-weight: 700;
}

.an-card-footer>img{
    width: 60px;
    height: 60px;
    padding: 1rem;
    padding-top: 0;
}
.alumnilogo{
    width: 40%;
    margin-bottom: 10px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
@media (max-width: 600px) {
    .AlumniNetwork{
        margin: 16px 10%;
    }
    
    h3{
        font-size: 25px;
        line-height: 29px;
        font-weight: 400;
        letter-spacing: 1px;
        font-weight: 700;
    }
    
    .an{
        display: flex;
        justify-content: space-between;
        padding-bottom: 2rem;
    }
    
    .an-top-right{
        display: flex;
        padding-right: 5rem;
        align-items: center;
    }
    
    .btn-cth{
        height: 42px;
        padding: 11px;
        margin-left: 28px;
        color: #ffffff;
        background-image: linear-gradient(to left, #00c4ff 0%, #0091ff 71%);
        border-color: #007bff;
        font-size: 14px;
        line-height: 14px;
        font-weight: 400;
        letter-spacing: 0px;
        border-width: 0px;
        border-radius: 5px;
    }
    
    .an-card-header>img{
        width: 52%;
       
    }
    
    .an-card-header{
     
        clip-path: polygon(0 0, 100% 0, 100% 60%, 0 88%);
        height: 249px;
    }
    
    .an-card{
       
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 40rem;
        background-color: #fff;
        width: 28rem !important;
        background-clip: border-box;
        border: 1px solid rgba(0,0,0,.125);
        border-radius: 8px;
        margin-bottom: 4rem !important;
        overflow: hidden;
        /* margin: 20px 30px; */
    }
    
    .alumni-cards{
        display: flex;
    }
    
    .an-review{
        margin: 0 0 15px;
        overflow-wrap: break-word;
        padding: 1rem;
        padding-top: 0;
        font-size: 1rem;
        width: 283px;
    }
    
    .an-details{
        display: flex;
        flex-direction: column;
        padding: 1rem;
        padding-top: 0;
    }
    .an-name{
        font-size:1rem;
    }
    .an-details>*{
        padding-bottom: 0.3rem;
       
    }
    
    .an-designation{
        font-weight: 700;
    }
    
    .an-card-footer>img{
        width: 60px;
        height: 60px;
        padding: 1rem;
        padding-top: 0;
    }
    .alumnilogo{
        width: 40%;
        margin-bottom: 10px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .alumnilogosection{
        width: 300px;
    }
   
  }