.CareerBanner{
    text-align: center;
    border-radius: 8px;
    box-shadow: 0 0 2px 0 rgb(0 0 0 / 50%);
    background-image: linear-gradient(117deg, #32c5ff, #0083e6 50%, #0083e6 50%);
    color: white;
    
    /* width: 80%; */
   
}

.head{
    font-size: 25px;
    line-height: 29px;
    font-weight: 400;
    letter-spacing: 1px;
}

.subhead{
    font-size: 20px;
    line-height: 25px;
    font-weight: 300;
    letter-spacing: 0px;
}

.feat>img{
    border-radius: 8px;
    height: 73px;
}

.images{
    margin-top: 3rem;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
}

.features{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 2rem;
}

.feat>h2{
    font-size: 30px;
    font-weight: bold;
    line-height: 44px;
    font-weight: 500;
    text-align: center;
    letter-spacing: 1px;
    word-wrap: break-word;
}

.feat>p{
    font-size: 20px;
    margin: 0 0 15px;
    text-align: center;
    overflow-wrap: break-word;
}
.feat{
    padding: 0 3rem;
}