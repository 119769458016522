.sfbox{
    display: flex;
    margin-bottom: 10px;
}
.logothree {
    width: 68px;
}
.textmargin{
    margin-left: 20px;
}
.images {
    width: 98%;
    height: 260px !important;
    border: 1px solid #007bff;
}
.slbuttonprv{
    margin-top: -256px !important;
    margin-left: -62px !important;
  }
  .slbuttonnext{
    float: right;
    margin-top: -134px !important;
    margin-right: -51px !important;
  }