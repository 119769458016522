.heading{
    font-size: 36px;
    line-height: 43px;
    font-weight: 400;
    letter-spacing: 1px;
    margin-bottom: 15px;
    overflow-wrap: break-word;
}

.program-section{
    margin: 0 10%;
}

.program-cards{
    display: flex;
    flex-wrap: wrap;
    margin-left: 2rem;
}

.program-menu{
    /* display: flex; */
    flex-direction: column;
    height: fit-content;
    margin-top: 1rem;
    position: sticky;
    top: 136px;
    width: fit-content;
    box-shadow: 0 0 20px 4px rgb(0 0 0 / 10%);
    border-radius: 8px;
}

.program-menu>button{
    padding: 1rem 2.5rem;
    background: none;
    font-size: 16px;
    border: none;
    cursor: pointer;
    text-align: start;
    color: #495057;
    width: 16rem;
    border-radius: 8px;
}

.active{
    background-color: #fa6400 !important;
    color: white !important;
    width: 285px!important;
}
.slick-dots li {
    margin-left: 10px;
}

.slick-dots {
    bottom: 6px;
}

.slick-dots li button:before, .slick-dots li.slick-active button:before {
    color: transparent;
    opacity: 1;

}

.slick-dots li button:before {
    margin-top: 5px;
    background-color: transparent;
    border: 1px solid #0094ff;
    border-radius: 50%;
    display: inline-block;
    background-color: #0094ff;
    height: 15px;
    width: 15px;
    opacity: .5;
 
}
.slick-dots li.slick-active button:before {
    background-color: #0094ff;
}