.btpaimageone
{
    width:200px !important;
    height:200px !important;
  }
  .desc
  {
    padding-top: 70px !important;
    padding-bottom: 50px !important;
  }
  .btpaimagetwo{
    width:260px !important;
    height:260px !important;
  }
  .hdesctwo{
    padding-top: 30px !important;
   
  }
  .descthre{
    padding-top: 55px !important;
  }
  .lastdesc{
    padding-top: 80px !important;
  
  }
  .container {
    position: relative;
    width: 100%;
  }
  
  .image {
    display: block;
    width: 100%;
    height: auto;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
   
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-image: radial-gradient(circle at 100% 0, rgba(0, 0, 0, 0), #000000 100%);
  }
  
  .container:hover .overlay {
    opacity: 1;
  }
  
  .text {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .overlays {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
   
    width: 70%;
    opacity: 0;
    transition: .5s ease;
    background-image: radial-gradient(circle at 100% 0, rgba(0, 0, 0, 0), #000000 100%);
  }
  
  .container:hover .overlays {
    opacity: 1;
  }
  @media screen and (max-width:780px) {
    .descthre{
      padding-top: 24px !important;
    }
  }
  @media screen and (max-width:500px) {
    .descthre{
      padding-top: 5px !important;
    }
    .desc
  {
    padding-top: 20px !important;
    padding-bottom: 50px !important;
  }
  .lastdesc{
    padding-top: 10px !important;
    padding-bottom: 20px !important;
  
  }
  }
  @media screen and (max-width:800px) {
    .overlays {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
     
      width: 90%;
      opacity: 0;
      transition: .5s ease;
      background-image: radial-gradient(circle at 100% 0, rgba(0, 0, 0, 0), #000000 100%);
    }
    
    .container:hover .overlays {
      opacity: 1;
    }

  }
  .flip-card {
    background-color: transparent;
    height: 400px;
    width: 370px;
    perspective: 1000px;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .flip-card-front {
    background-color: #bbb;
    color: black;
  }
  
  .flip-card-back {
    background:linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 41, 255, 1) 0%, rgba(0, 145, 255, 1) 100%);
    color: white;
    transform: rotateY(180deg);
    height: 400px;
    width: 370px;
  }