.place-Supp{
    margin: 0 10%;
}

.sub-head{
    font-size: 17px;
    line-height: 21px;
    font-weight: 400;
    letter-spacing: 1px;
}

.head{
    font-weight: 700;
}

.plc-in>h3{
    margin-top: 0;
}

.plc-sup{
    display: flex;
    padding: 6px 0 12px 0;
    margin-bottom: 2rem;
}

.plc-img-div>img{
    width: 50px;
    height: 50px;
}

.plc-img-div{
    height: 3rem;
    border-radius: 8px;
    border: solid 1px #0091ff;
    padding: 16px;
    margin-right: 2rem;
}

.plc-right>img{
    width: 35rem;
}
.plc-img-div .image::before {
    content: '';
    position: absolute;
    width: 1px;
    height: 80px;
    /* top: -3%; */
    left: 23%;
    background: red;
    margin-top: 66px;
    /* z-index: -1; */
    /* bottom: -52px; */
}
.plc-img-div .image::after {
    content: '';
    position: absolute;
    width: 1px;
    height:50px;
    /* top: -3%; */
    left:23%;
    background: red;
    margin-top: -71px;
    /* z-index: -1; */
    /* bottom: -52px; */
}
.plc-img-div .newimage::before {
    content: '';
    position: absolute;
    width: 1px;
    height:65px;
    /* top: -3%; */
    left: 11%;
    background: red;
    margin-top: 66px;
    /* z-index: -1; */
    /* bottom: -52px; */
}
.plc-img-div .newimage::after {
    content: '';
    position: absolute;
    width: 1px;
    height: 37px;
    /* top: -3%; */
    left: 11%;
    background: red;
    margin-top: -56px;
    /* z-index: -1; */
    /* bottom: -52px; */
}
.lastimage{
    width: 63px;
}

@media screen and (max-width: 600px) {

    .plc-img-div .image::before {
        content: '';
        position: absolute;
        width: 1px;
        height:77px;
        /* top: -3%; */
        left:20% !important;
        background: red;
        margin-top: 66px;
        /* z-index: -1; */
        /* bottom: -52px; */
    }
    .plc-img-div .image::after {
        content: '';
        position: absolute;
        width: 1px;
        height:50px;
        /* top: -3%; */
        left:20% !important;
        background: red;
        margin-top: -71px;
        /* z-index: -1; */
        /* bottom: -52px; */
    }
}

@media screen and (min-width: 601px) and (max-width: 1050px) {

    .plc-img-div .image::before {
        content: '';
        position: absolute;
        width: 1px;
        height:50px;
        /* top: -3%; */
        left: 15%;
        background: red;
        margin-top: 66px;
        /* z-index: -1; */
        /* bottom: -52px; */
    }
    .plc-img-div .image::after {
        content: '';
        position: absolute;
        width: 1px;
        height:50px;
        /* top: -3%; */
        left:15%;
        background: red;
        margin-top: -71px;
        /* z-index: -1; */
        /* bottom: -52px; */
    }
}
@media screen and (min-width: 1051px) and (max-width: 1200px) {

    .plc-img-div .image::before {
        content: '';
        position: absolute;
        width: 1px;
        height:50px;
        /* top: -3%; */
        left: 15%;
        background: red;
        margin-top: 66px;
        /* z-index: -1; */
        /* bottom: -52px; */
    }
    .plc-img-div .image::after {
        content: '';
        position: absolute;
        width: 1px;
        height:50px;
        /* top: -3%; */
        left:15%;
        background: red;
        margin-top: -71px;
        /* z-index: -1; */
        /* bottom: -52px; */
    }
}
@media screen and (min-width: 1200px) and (max-width: 1450px) {

    .plc-img-div .newimage::before {
        content: '';
        position: absolute;
        /* display: grid;
        place-items: center; */
        width: 1px;
        height:65px;
        /* top: -3%; */
        /* left: 15%; */
        background: red;
        margin-top: 66px;
        /* z-index: -1; */
        /* bottom: -52px; */
    }
    .plc-img-div .newimage::after {
        content: '';
        position: absolute; 
        width: 1px;
        height: 37px;
        /* top: -3%; */
        /* display: grid;
        place-items: center; */
        /* left: 15%; */
        background: red;
        margin-top: -56px;
        /* z-index: -1; */
        /* bottom: -52px; */
    }
    
}
@media screen and (min-width: 1451px) and (max-width: 1600px) {

    .plc-img-div .newimage::before {
        content: '';
        position: absolute;
        /* display: grid;
        place-items: center; */
        width: 1px;
        height:65px;
        /* top: -3%; */
        left: 15%;
        background: red;
        margin-top: 66px;
        /* z-index: -1; */
        /* bottom: -52px; */
    }
    .plc-img-div .newimage::after {
        content: '';
        position: absolute; 
        width: 1px;
        height: 37px;
        /* top: -3%; */
        /* display: grid;
        place-items: center; */
        left: 15%;
        background: red;
        margin-top: -56px;
        /* z-index: -1; */
        /* bottom: -52px; */
    }
    
}