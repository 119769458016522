

.home{
    font-family: Helvetica,sans-serif;
}

.head-one{
    font-size: 28px;
    line-height: 25px;
    font-weight: 300;
    letter-spacing: 0px;
    margin-bottom: 15px;
}
.head-sec{
    font-size: 38px;
    margin-bottom: 0!important;
    line-height: 44px;
    font-weight: 500;
    letter-spacing: 1px;
}

p{
    margin: 0 0 15px;
    margin-block-start: 1em;
    margin-block-end: 1em;
    text-align: left;
    margin-bottom: 1.5rem!important;
}

.btn-ttex{
    font-size: 22px;
    color: #ffffff;
    background-image: linear-gradient(to left, #00c4ff 0%, #0091ff 71%);
    border-color: #007bff;
    line-height: 14px;
    font-weight: 400;
    border-width: 0px;
    border-radius: 5px;
    margin: 1rem 0;
    padding: 1rem 2rem;
    cursor: pointer;
}

.text-info-parent{
    height: 4rem;
    display: flex;
    margin-top: 1rem;
}

.img-text-info{
    width: 40px;
}

.text-info{
    display: flex;
    align-items: center;
}

.text-info>span{
    margin-left: 1rem;
}

.fade-in {
    transition: opacity 1s ease;
}
.fade-out {
    opacity: 0;
    transition: opacity 1s ease;
}

.home-first-section-card{
    border: 2px solid blue;
    border-radius: 8px;
    overflow: hidden;
    width: 119px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.25rem;
    align-items: center;
}

.home-first-section-card>div{
    font-weight: 700;
    margin: 0.5rem 0;
}

.home-first-section-card-img-parent{
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid blue;
    margin-bottom: 1rem;
}

.home-first-section-card-img-parent>img{
    width: 100px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.home-first-section-card-company{
    width: 100px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin: 1rem 0;
}

.home-first-section-allCards{
    display: block;
    width: 500px;
    height: 300px;
}

.slide{
    transform: scale(0.7);
    transition: transform 300ms;
    opacity: 0.5;
    display: flex;
}

.activeSlide{
    transform: scale(1);
    opacity: 1;
    display: flex;
}
