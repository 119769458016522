.Card__V3_cardContainer__ZkixJ {
    border-radius: 8px;
    position: relative;
    box-shadow: 0 4px 10px rgba(0,0,0,.2);
    /* height: 100%; */
    background-color: #fff;
/*     width: 319px; */
}
.Card__V3_ribbonContainer__wqJfc {
    position: absolute;
    top: 10px;
    left: -14px;
    background: linear-gradient(110.83deg,#fa8888 15.83%,#f82c4e 97.46%);
    border-radius: 0 4px 4px 0;
    display: flex;
    align-items: center;
    z-index: 2;
    font-weight: 500;
    color: #fff;
}
.Card__V3_ribbonText__KAc05 {
    position: relative;
    width: 100%;
    height: 100%;
    font-size: 15px;
    line-height: 15px;
    padding: 4px 8px 4px 14px;
}
.Card__V3_ribbonText__KAc05:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-top: 12px solid #8c1212;
    border-left: 14px solid transparent;
    top: 100%;
    left: 0;
}
.Card__V3_exceptImageContainer__WHeGL {
    background-color: #fff;
    width: 100%;
    display: block;
    border-radius: 10px;
    margin-top: -16px;
    position: relative;
}
.Card__V3_exceptImageContainer__WHeGL:hover {
    background-color: #fff;
    width: 100%;
    display: block;
    border-radius: 10px;
    margin-top: -16px;
    position: relative;
	text-decoration:none;
}
.Card__V3_universityContainer__0luyg {
    padding: 15px 0 12px;
    margin: 0 16px;
    border-bottom: 1px solid #f4f5f7;
    display: flex;
    gap: 10px;
    align-items: center;
    border-radius:10px;
}
.Card__V3_universityIcon__qjO7_ {
    height: 48px !important;
    max-width: 120px;
    -o-object-fit: contain;
    object-fit: contain;
}
.Card__V3_divider__jtGnS {
    width: 0;
    height: 20px;
    border-right: 1px solid #dfe1e6;
    display: block;
}
.Card__V3_univLabel__ipdFt {
    font-size: 16px;
    color: #010101;
    line-height: 100%;
    flex: 1 1;
    font-weight: 400;
}
.Card__V3_courseTitle__iZ0oT {
    margin: 12px 16px 16px !important;
    font-size: 18px !important;
    color: #222 !important;
    line-height: 22px !important;
    font-weight: bold !important;
    height: 44px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
.Card__V3_pointersSection__QAWOO {
    padding: 0 16px;
}
.Card__V3_eachPointerContainer__m1dnV {
    display: flex;
    gap: 8px;
    margin-bottom: 6px;
}
.Card__V3_pointerLabel__s2g6c {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #222;
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 0;
    white-space: nowrap;
    flex: 1 1;
}
.Card__V3_eachPointerContainer__m1dnV {
    display: flex;
    gap: 8px;
    margin-bottom: 6px;
}
.Card__V3_pointerLabel__s2g6c {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #222;
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 0;
    white-space: nowrap;
    flex: 1 1;
}
.Card__V3_viewCta__62BZ_ {
    margin-top: 24px;
    height: 44px;
    border-top: 1px solid #d8d8d8;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 10px 10px;
}
.Card__V3_viewCta__syllabusCta__okTSo {
    display: flex;
    justify-content: center;
    width: 50%;
    color: #e95454;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    height: 100%;
    align-items: center;
}
.Card__V3_viewCta__syllabusCta__syllabusText___DxWV {
    margin-left: 4px;
	font-size:16px;
    display: flex;
    cursor: pointer;
}
.Card__V3_ctaText__EhpMk {
    font-size: 14px;
    color: #fff;
    background-color: #e95454;
    font-weight: 600;
    line-height: 16px;
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 8px 0;
}
.spanclas{
	 box-sizing: border-box;
        display: inline-block;
        overflow: hidden;
        width: initial;
        height: initial;
        background: none;
        opacity: 1;
        border: 0px;
        margin: 0px;
        padding: 0px;
        position: relative;
        max-width: 100%;
}
.cardimge{
	 
            display: block;
            max-width: 100%;
            background: none;
            opacity: 1;
            border: 0px;
            margin: 0px;
            padding: 0px;
	height: 216px !important;
    width: 379px;
	    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
     
}
@media only screen and (max-width: 600px){
	.cardimge{
	 
            display: block;
            max-width: 100%;
            background: none;
            opacity: 1;
            border: 0px;
            margin: 0px;
            padding: 0px;
	height: 171px !important;
    width: 379px;
		    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
     
}
	.Card__V3_viewCta__syllabusCta__syllabusText___DxWV {
    margin-left: 4px;
	font-size:11px;
}
.Card__V3_ctaText__EhpMk {
    font-size: 12px;
    color: #fff;
    background-color: #e95454;
    font-weight: 600;
    line-height: 16px;
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 8px 0;
}
}