.banner-alumni-comp>h2{
    font-size: 42px;
    line-height: 42px;
    float: left;
    font-weight: 500;
    letter-spacing: 1px;
}

.banner-alumni-comp{
    background-color: rgba(0, 145, 255, 0.05);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.img-comp{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}

.img-comp>img{
    width: 132px;
    margin: 0 2rem;
}