.card {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  background-color: #fff;
  width: 14rem;
  height: 304px !important;
  height: fit-content;
  padding: 0 2rem 2rem 2rem;
  border-radius: 10px;
  border: 2px solid #007bff;
}


.card img {
  margin-top:25%;
 
  border-radius: 50px;
  border: 3px solid #007bff;
  object-fit: none;
width: 100px;

}
.compntimg {
  margin-top: 1% !important;
  border-radius:1px !important;
  border: 1px solid transparent !important;
width: 135px !important;
}

.card h2 {
  margin: 0;
  margin-top: 1rem;
}

.card p {
  margin: 0;
  margin-top: 0.9rem;
  margin-bottom:1rem !important;
  color:#000;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
}

.card h6 {
  margin: 0;
  margin-top: 0.9rem;
  margin-bottom:1rem !important;
  color:#000;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
 
}

.card .btnn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cardbox{
  width: 30%;
  height: 500px;
  margin: -60px 188px;
}
@media (max-width: 1180px) {
  .cardbox{
    width:10%;
    height: 500px;
  
  }
}